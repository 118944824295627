import {environmentVars} from './internalTest';
import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'terrific'};
export const environment = {
  ...environmentVars,
  firebaseConfig: {
    apiKey: 'AIzaSyCkWQ4_GbRT_GpXs5clfayvbB0bbnTH5ss',
    authDomain: 'devs.terrific.live',
    projectId: 'terrific-devs',
    storageBucket: 'terrific-devs.appspot.com',
    messagingSenderId: '278205526112',
    appId: '1:278205526112:web:679f7df9787097bbaad305',
    measurementId: 'G-30211ZSK09',
  },
  traceOrigin: {
    name: 'terrific-devs',
    domain: 'devs.terrific.live',
  },
  client,
  cspGuardUrl: 'https://us-central1-terrific-devs.cloudfunctions.net/cspGuardV2',
};
